@tailwind base;
@tailwind components;
@tailwind utilities;

//@import '~froala-editor/css/froala_style.min.css';
//@import '~react-datepicker/dist/react-datepicker.css';
//@import './utilities/reset';
//@import './utilities/normalize';
//
//@import './utilities/variables';
//@import './utilities/mixins';
@import './utilities/general';
@import './utilities/wysiwyg';
//
//@import './utilities/grid';
//@import './utilities/helpers';
//
//@import './plugin_overwrites/plugins';
//@import './atoms/atoms';
@import './components/_contact';
@import './components/_product-carousel';
//
//@import './utilities/utilities';
