@layer ignore {
  .pointless {
    // Fix for: https://github.com/tailwindlabs/tailwindcss/discussions/7688
    x-unsupported-property: inherit;
  }
}

input:focus {
  outline: none;
}

.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.headroom--unfixed {
  position: relative;
  transform: translate3D(0, 0, 0);
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translate3D(0, -100%, 0);
}
.headroom--pinned {
  position: fixed;
  transform: translate3D(0, 0, 0);
}

.slider {
  @apply flex flex-wrap;

  &, &:focus {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    box-shadow: 0 0 #0000;
  }
}
.slider:after {
  @apply content-['flickity'] hidden;
}
/* Adds support for sm: md: xl: etc prefixes like xl:no-slider */
@responsive {
  .no-slider:after {
    @apply content-[none] pointless;
  }
}
.slider .flickity-viewport {
  @apply basis-full mb-10 overflow-visible;
}
.slider .flickity-viewport .flickity-slider {
  @apply space-x-5 lg:space-x-9;
}
.slider.related-products .flickity-viewport .flickity-slider {
  @apply space-x-4;
}
.slider .flickity-page-dots {
  @apply flex items-center static flex-1 order-2 mx-0;
}
.slider .flickity-page-dots .dot {
  @apply flex-1 w-auto h-[1px] bg-dark-grey rounded-none m-0 pr-1;
}
.slider .flickity-page-dots .dot.is-selected {
  @apply h-[3px] bg-yellow;
}
// slider with dots
.slider .flickity-page-dots {
  @apply flex items-center justify-center flex-1 gap-3 order-2 mx-0;
}
.slider .flickity-page-dots .dot {
  @apply block flex-none w-4 h-4 bg-light-grey rounded-full;
}
.slider .flickity-page-dots .dot.is-selected {
  @apply h-4 bg-dark-navy;
}

.slider {
  &.bar-slider {
    @apply pb-1 flex items-center gap-4;
    .flickity-button {
      @apply h-4 w-4 text-white fill-white;
      order: 3;
    }
    .flickity-viewport {
      order: 1;
    }
    .flickity-page-dots {
      display: flex;
      flex-wrap: nowrap;
      gap: 0;
      @apply bg-white rounded-full mr-8;
      height: 1px;
      overflow: show;
      order: 2;
      padding-left: 0;
      .dot {
        height: 100%;
        display: flex;
        flex: 1;
        width: auto;
        opacity: 0;
        @apply bg-white;
        &.is-selected {
          position: relative;
          opacity: 1;
          &::after {
            @apply bg-white rounded-full;
            transform: scale(1, 1.3);
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: 0;
          height: 3px;
          width: 100%;
          transform: scale(0, 1);
          -webkit-transform-origin: 0% 100%;
          -ms-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
          -webkit-transition: all 0.3s linear;
          transition: all 0.3s linear;
        }
      }
    }
    &.dark {
      .flickity-page-dots {
        @apply bg-dark-navy;
        .dot {
          @apply bg-dark-navy;
          &.is-selected {
            &::after {
              @apply bg-dark-navy;
              transform: scale(1, 1.3);
            }
          }
        }
      }
    }
  }
}

.yellow-slider .yellow-flickity .flickity-viewport {
  @apply relative;
}

.yellow-slider .yellow-flickity .flickity-page-dots {
  @apply flex static w-full items-center gap-0;
}

.yellow-slider .yellow-flickity .flickity-page-dots .dot {
  @apply rounded-none h-[2px] m-0 bg-dark-navy flex-1;
}

.yellow-slider .yellow-flickity .flickity-page-dots .dot.is-selected {
  @apply rounded-none h-[6px] m-0 bg-yellow;
}

.yellow-slider .yellow-flickity.yellow-with-buttons .previous {
  order: 4;
  margin-left: 20px;
}

.yellow-slider .yellow-flickity.yellow-with-buttons .next {
  order: 5;
}

.yellow-slider .yellow-flickity.yellow-with-buttons .flickity-button {
  height: 20px;
  width: 20px;
  order: 5;
  fill: #FFD23F;
}

.products-on-article {
  &:after {
    content: '';
  }
  @media( max-width: 768px ) {
    /* disable Flickity for large devices */
    &:after {
      content: 'flickity';
      display: none; /* hide :after */  
    }
  }
}

.background-shapes {
  @apply relative overflow-hidden;

  &.yellow:before {
    background-image: url('../../../public/images/background-shape1-yellow.svg');
    background-repeat: no-repeat;
  }
  &.yellow:after {
    background-image: url('../../../public/images/background-shape2-yellow.svg');
    background-repeat: no-repeat;
  }
}
.background-shapes:before {
  @apply absolute z-[-1] top-[-80px] right-[-90px] content-[''] bg-[url('../../public/images/background-shape1.svg')] w-[241px] h-[269px] md:w-[656px] md:h-[580px] bg-contain bg-no-repeat bg-right;
}
.background-shapes:after {
  @apply absolute z-[-1] bottom-0 left-0 content-[''] bg-[url('../../public/images/background-shape2.svg')] w-[174px] h-[192px] md:w-[284px] md:h-[315px] bg-contain bg-no-repeat bg-left;
}
.background-shapes-dark {
  @apply relative overflow-hidden;
}
.background-shapes-dark > * {
  @apply relative;
}
.background-shapes-dark:before {
  @apply absolute top-[-100px] right-[-40px] content-[''] bg-[url('../../public/images/background-shape3.svg')] w-[241px] h-[269px] md:w-[482px] md:h-[538px] bg-contain bg-no-repeat bg-right;
}
.background-shapes-dark:after {
  @apply absolute bottom-[-30px] left-[-5px] content-[''] bg-[url('../../public/images/background-shape4.svg')] w-[84px] h-[91px] md:w-[84px] md:h-[91px] bg-contain bg-no-repeat bg-left;
}

.background-shapes-home {
  @apply relative overflow-hidden;
}
.background-shapes-home:before {
  @apply absolute z-[-1] sm:bottom-[-200px] bottom-0 sm:right-[-90px] right-0 content-[''] bg-[url('../../public/images/background-shape1.svg')] w-[84px] h-[91px] sm:w-[656px] sm:h-[580px] bg-contain bg-no-repeat bg-right;
}
.background-shapes {
  &.top-only {
    &::after {
      content: none;
    }
  }
}

header {
  @apply transition-all;
}
.headroom--unpinned {
  //@apply translate-y-[-100%] pointless;
}

.placeholder-black::placeholder {
  color: black;
}

.failed-validation, .StripeElement--invalid {
  border: 1px solid #EA4751;
  background: #fbc9cb;

  &, &::placeholder {
    color: #EA4751;
  }
}

.passed-validation {
  border: 1px solid #52ea47;
  background: #d0fbc9;

  &, &::placeholder {
    color: #43ad3f;
  }
}

.mastery-list {
  margin-left: 0px!important;
  list-style-type: none!important;
  li {
    @apply bg-[url('../../public/images/mastery-bullet.svg')] bg-no-repeat pl-[40px] mb-4 bg-left;
    list-style-type: none!important;
  }
}

@responsive {
  .column-count-2 {
    column-gap: 60px;
    column-count: 2;
  }
}