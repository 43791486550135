.carousel-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* cell number */
    &:before {
    //   content: counter(carousel-cell);
    }
  }
  
  .carousel-main{
    position: relative;
    .carousel-cell{
      width: 100%;
      opacity: 0;
      &.is-selected {
        opacity: 1;
      }
    }
    .flickity-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @apply bg-white text-black rounded p-2 transition-all ease-in-out duration-200;
        svg {
            height: 14px;
        }
        &:hover {
            &:is(:not(:disabled)) {
                @apply bg-yellow;
            }
        }
        &.next {
            right: 16px;
        }
        &.previous {
            left: 16px;
        }
    }
    .flickity-page-dots {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        @apply mt-6;
        z-index: 100;
        .dot {
            height: 12px;
            width: 12px;
            border-radius: 100%;
            @apply bg-light-grey;
            &.is-selected {
                @apply bg-yellow;
            }
        }
        @media(max-width: 576px) {
            transform: translateY(-50px);
        } 
    }
  }
  
  .carousel-nav{
    width: 100%;
    transform: rotate(90deg) translate(0, 0);

    
    @media screen and (max-width: 1200px) {
      transform: rotate(90deg) translate(0, 0);
    }
    .flickity-viewport {
        width: 100%;
    }
  
    .carousel-cell {
      transform: rotate(90deg);
      cursor: pointer;
      margin-right: 8px;
      font-size: 1.4rem;
      
      img {
        transform: rotate(-90deg);
      }
     
      /* selected cell */
      &.is-nav-selected {
        // background: #ED2;
      }
    }
    
    .flickity-prev-next-button {
      width: 40px;
      height: 40px;
      background: transparent;
  
      &.previous{
        left: -40px;
      }
      &.next{
        right: -40px;
      }
    }
  }