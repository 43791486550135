.wysiwyg {
  img {
    @apply rounded-xl;
    margin: 50px auto;

    &.first-image {
      max-width: 100%;
      @apply mt-[-110px] xl:ml-[-100px] xl:mr-[-100px] mb-[50px] xl:mt-[-160px] relative z-10;
      //@apply max-w-[1040px];

      @media (min-width: 1280px) {
        max-width: none;
        width: calc(100% + 200px);
      }
    }
  }

  h1, h2, h3, h4, h4, h6 {
    @apply text-yellow;
  }
}
.post-container {
  @apply flex;
  @apply flex-col;
  @apply items-center;
}